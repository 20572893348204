import logo from "@/assets/dddiLogo.jpg";
import InSvg from "@/assets/svg/InSvg";
import Icon from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import wx from "@/assets/img/wx.png";
import { useState } from "react";
import { useNavigate, Link } from 'react-router-dom';

const Footer = () => {
  const aStyle = {
    color: '#fff',
    textDecoration: "none"
  }
  const { t, i18n } = useTranslation();
  const [showWX, showWXchange] = useState(false);
  const navigate = useNavigate();

  const handlePartnerClick = (e, url, anchor) => {
    e.preventDefault();
    navigate(url);
    setTimeout(() => {
      const element = document.getElementById(anchor);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }, 100);
  };

  return (
    <>
      <footer className={`w-full text-white bg-[--color-footer-bg]`}>
        <div className="page-content a-hover-underline-list">
          <div className="lg:flex justify-between border-t-2 border-gray-500 py-10">
            <div className="lg:w-2/5 flex flex-col gap-5 mr-20">
              <div className="flex flex-col items-start">
                <div className="mt-4 text-left text-lg font-bold" style={{ fontSize: "3rem" }}>
                  <p>
                    <span className="text-red-600">D</span>
                    <span className="text-white">{t('footerDataText')}</span>
                  </p>
                  <p>
                    <span className="text-red-600">D</span>
                    <span className="text-white">{t('footerDrivenText')}</span>
                  </p>
                  <p>
                    <span className="text-red-600">D</span>
                    <span className="text-white">{t('footerDevelopmentText')}</span>
                  </p>
                  <p>
                    <span className="text-red-600">I</span>
                    <span className="text-white">{t('footerIntelligenceText')}</span>
                  </p>
                </div>
              </div>
              <div className="text-sm" style={{ fontSize: 15, paddingTop: 40 }}>
                <p>{t('48')}</p>
              </div>
            </div>
            <div className="lg:w-3/5 flex flex-col lg:flex-row text-sm mt-10 lg:mt-0">
              {/* Left Column */}
              <div className="flex flex-col space-y-8 lg:w-1/2">


                <ul className="flex flex-col space-y-2">
                  <li className="font-bold text-xl">
                    <a href="/" style={aStyle}>
                      {t('1')}
                    </a>
                  </li>
                </ul>

                <ul className="flex flex-col space-y-2">

                  <li className="font-bold text-xl">{t('researches')}</li>
                  <li><a
                    onClick={(e) => handlePartnerClick(e, '/big-data', 'cyberate-technologies')}
                    href="/#" style={aStyle}>{t('bigDataAnalytics')}</a></li>
                  <li><a
                    onClick={(e) => handlePartnerClick(e, '/osint', 'cyberate-technologies')}
                    href="/#" style={aStyle}>{t('openSourceIntelligence')}</a></li>
                  <li><a
                    onClick={(e) => handlePartnerClick(e, '/vrar', 'cyberate-technologies')}
                    href="/#" style={aStyle}>{t('vrArTitle')}</a></li>
                  {/* <li><a
  onClick={(e) => handlePartnerClick(e, '/digital-twin', 'cyberate-technologies')}
  href="/#" style={aStyle}>{t('buildingConstructionManagement')}</a></li> */}
                  <li><a
                    onClick={(e) => handlePartnerClick(e, '/digital-twin', 'cyberate-technologies')}
                    href="/#" style={aStyle}>{t('digitalTwin')}</a></li>
                </ul>

                <ul className="flex flex-col space-y-2">
                  <li className="font-bold text-xl"><a
                    href="/newsroom"
                    style={aStyle}
                  >
                    {t('news')}
                  </a></li>
                </ul>

                <ul className="flex flex-col space-y-2">
                  <li className="font-bold text-xl">
                    <a href="/projects" style={aStyle}>
                      {t('projects')}
                    </a>
                  </li>
                </ul>



                {/* 
                <ul className="flex flex-col space-y-2">
                  <li className="font-bold text-xl">{t('services')}</li>
                  <li><a
                    onClick={(e) => handlePartnerClick(e, '/group-members', 'cyberate-technologies')}
                    href="/group-members#cyberate-technologies" style={aStyle}>{t('forInvestors')}</a></li>
                  <li><a
                    onClick={(e) => handlePartnerClick(e, '/group-members', 'cyberate-technologies')}
                    href="/group-members#cyberate-technologies" style={aStyle}>{t('forIndividualBuyers')}</a></li>
                </ul> */}


                {/* <ul className="flex flex-col space-y-2">
                  <li className="font-bold text-xl">{t('footerCareers')}</li>
                  <li>
                    <a href="/careers" style={aStyle}>{t('applyNow')}</a>
                  </li>
                </ul> */}

                <ul className="flex flex-col space-y-2">
                  {/* <li className="font-bold text-xl"><a style={aStyle} href="/ourPartners">{t('footerPartners')}</a></li> */}
                  {/* <li>
                    <a
                      href="/ourPartners#realEstate"
                      onClick={(e) => handlePartnerClick(e, '/ourPartners', 'realEstate')}
                      style={aStyle}
                    >
                      {t('footerRealEstate')}
                    </a>
                  </li>
                  <li>
                    <a
                      href="/ourPartners#dataSupport"
                      onClick={(e) => handlePartnerClick(e, '/ourPartners', 'dataSupport')}
                      style={aStyle}
                    >
                      {t('footerDataSupport')}
                    </a>
                  </li>
                  <li>
                    <a
                      href="/ourPartners#legalSupport"
                      onClick={(e) => handlePartnerClick(e, '/ourPartners', 'legalSupport')}
                      style={aStyle}
                    >
                      {t('footerLegalSupport')}
                    </a>
                  </li> */}
                </ul>
              </div>

              {/* Right Column */}
              <div className="flex flex-col space-y-8 lg:w-1/2 mt-8 lg:mt-0">

                <ul className="flex flex-col space-y-2">
                  <li className="font-bold text-xl">{t('footerProudMembers')}</li>
                  <li><a
                    onClick={(e) => handlePartnerClick(e, '/group-members', 'cyberate-technologies')}
                    href="/group-members#cyberate-technologies" style={aStyle}>Cyberate Technologies</a></li>
                  <li><a
                    onClick={(e) => handlePartnerClick(e, '/group-members', 'cyberate-investment')}
                    href="/group-members#cyberate-investment" style={aStyle}>Cyberate Investments</a></li>
                  <li><a
                    onClick={(e) => handlePartnerClick(e, '/group-members', 'cyberate-finance')}
                    href="/group-members#cyberate-finance" style={aStyle}>Cyberate Finance</a></li>
                  <li><a
                    onClick={(e) => handlePartnerClick(e, '/group-members', 'cyberate-project-management')}
                    href="/group-members#cyberate-project-management" style={aStyle}>Cyberate Project Management</a></li>
                  <li><a
                    onClick={(e) => handlePartnerClick(e, '/group-members', 'cyberidge-construction')}
                    href="/group-members#cyberidge-construction" style={aStyle}>Cyberidge Construction</a></li>
                  <li><a
                    onClick={(e) => handlePartnerClick(e, '/group-members', 'quant-digital')}
                    href="/group-members#quant-digital" style={aStyle}>Quant Digital</a></li>
                </ul>



                <ul className="flex flex-col space-y-2">
                  <li className="font-bold text-xl">{t('footerAboutUs')}</li>
                  <li>
                    <Link to="/about-us/team" style={aStyle}>{t('ourTeam')}</Link>
                  </li>
                  <li>
                    <Link to="/ourPartners" style={aStyle}>{t('ourPartners')}</Link>
                  </li>
                  <li>
                    <Link to="/about-us/our-fleet" style={aStyle}>{t('ourFleet')}</Link>
                  </li>
                  <li>
                    <Link to="/careers" style={aStyle}>{t('career')}</Link>
                  </li>
                  {/* <li>
                    <a
                      onClick={(e) => handlePartnerClick(e, '/about-us', 'ContactUs')}
                      href="/contact-us" style={aStyle}>{t('footerContactUs')}</a>
                  </li> */}
                </ul>


                {/* <ul className="flex flex-col space-y-2">
                  <li className="font-bold text-xl"><a
                    href="/contact-us"
                    style={aStyle}
                  >
                    {t('contactUs')}
                  </a></li>
                </ul> */}

                <ul className="flex gap-x-5">
                  <li>
                    <div className="flex flex-row items-center lg:flex-col lg:items-end lg:ml-auto">
                      <a href="https://www.linkedin.com" target="_blank" rel="noopener noreferrer" className="text-gray-100 hover:text-blue-800 mx-4" >
                        <i className="fab fa-linkedin fa-2x "></i>
                      </a>
                      <div className="text-gray-100 hover:text-green-800 mx-4" style={{ position: "relative" }} onClick={() => showWXchange(!showWX)}>
                        <i className="fab fa-weixin fa-2x"></i>
                        {showWX && <div style={{ position: "absolute", top: "calc(-10rem - 60px)", left: "20px", width: "10rem", height: "5rem" }}>
                          <img src={wx}></img>
                        </div>}
                      </div>
                      <a
                        href="https://www.xiaohongshu.com/user/profile/6642b1750000000007007702?xsec_token=&xsec_source=pc_search"
                        className="text-gray-100 hover:text-red-600 mx-4"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i className="fas fa-book fa-2x"></i>
                      </a>
                      <div
                        className="text-gray-100 hover:text-yellow-600 mx-4"
                        onClick={() => { window.location.href = 'mailto:info@dddi.com.au' }}
                      >
                        <i className="fas fa-envelope fa-2x"></i>
                      </div>
                    </div>
                  </li>
                </ul>

              </div>
            </div>
          </div>

          <div className="py-10 text-sm flex flex-col lg:flex-row justify-between items-center">
            {/* <ul className="flex gap-x-5">
              <li>
                <div className="flex flex-row items-center lg:flex-col lg:items-end lg:ml-auto">
                  <a href="https://www.linkedin.com" target="_blank" rel="noopener noreferrer" className="text-gray-100 hover:text-blue-800 mx-4" >
                    <i className="fab fa-linkedin fa-2x "></i>
                  </a>
                  <div className="text-gray-100 hover:text-green-800 mx-4" style={{ position: "relative" }} onClick={() => showWXchange(!showWX)}>
                    <i className="fab fa-weixin fa-2x"></i>
                    {showWX && <div style={{ position: "absolute", top: "calc(-10rem - 60px)", left: "20px", width: "10rem", height: "5rem" }}>
                      <img src={wx}></img>
                    </div>}
                  </div>
                  <a
                    href="https://www.xiaohongshu.com/user/profile/6642b1750000000007007702?xsec_token=&xsec_source=pc_search"
                    className="text-gray-100 hover:text-red-600 mx-4"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fas fa-book fa-2x"></i>
                  </a>
                  <div
                    className="text-gray-100 hover:text-yellow-600 mx-4"
                    onClick={() => { window.location.href = 'mailto:info@dddi.com.au' }}
                  >
                    <i className="fas fa-envelope fa-2x"></i>
                  </div>
                </div>
              </li>
            </ul> */}
          </div>

          <p className="text-xs pb-8 text-center lg:text-left">
            {t('footerCopyright')} <span style={{ float: 'right' }}>v1.0.7</span>
          </p>
        </div>
      </footer>
    </>
  );
};

export default Footer;
