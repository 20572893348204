import SEO from "@/common/SEO";
import Layout from "@/common/Layout";
import Banner from "@/components/banner/Banner";
import CarouselList from "@/components/carousel-list/CarouselList";
import CardQuote from "@/components/card/card-quote/CardQuote";
import CornerMarkList from "@/components/corner-mark-list/CornerMarkList";
import HeadCarousel from "@/components/head-carousel/HeadCarousel";
import GroupMembers from "@/components/brand/groupMembers";

import Img01 from "@/assets/img/image01.jpg";
import Separator from "../careers/Separator";
import TeamThree from "../people/Teammember";
import { useTranslation } from "react-i18next";


import ContactUs from "@/components/contact-us/ContactUs";
const GroupMembersPage = () => {
  const { t } = useTranslation();
  const companies = [
    {
      name: "Cyberate Technologies",
      img: "/images/brand/Cyberate.jpg",
      link: "https://www.cyberate.com.au/",
      description: t('19'),
      content: t('cyberateTechnologiesDesc'),
      introduction: t('cyberateTechnologies'),
    },
    {
      name: "Cyberate Investments",
      img: "/images/brand/Investment.jpg",
      link: "https://www.cyberate.investments/",
      description: t('20'),
      content: t('cyberateInvestmentsDesc'),
      introduction: t('cyberateInvestments'),
    },
    {
      name: "Cyberate Finance",
      img: "/images/brand/Finance.jpg",
      link: "https://cyberate.finance/",
      description: t('developmentLoan'),
      content: t('cyberateFinanceDesc'),
      introduction: t('cyberateFinance'),
    },
    {
      name: "Cyberate Project Management",
      img: "/images/brand/Project management.jpg",
      link: "https://pm.dddi.com.au/",
      content: t('cyberatePMDesc'),
      description: t('22'),
      introduction: t('cyberateProjectManagement'),
    },
    {
      name: "Cyberidge Construction",
      img: "/images/brand/Cyberidge.jpg",
      content: t('cyberidgeConstructionDesc'),
      link: "https://www.cyberidge.com.au/",
      description: t('28'),
      introduction: t('cyberidgeConstruction')
    },
    {
      name: "Quant Digital",
      img: "/images/brand/Quant Digital.jpg",
      content: t('quantDigitalConstructionDesc'),
      link: "https://www.quantdigital.au/",
      description: t('28'),
      introduction: t('quantDigitalConstruction')
    },
  ];
  return (
    <>
      <SEO title="Group Members"></SEO>
      <Layout>
        <Banner
          title="Our Group Members"
          bgColor="#F2EDE8"
          textColor="rgb(41, 55, 102)"
          img={Img01}
        ></Banner>

        <div style={{ height: "40px" }}></div>

        {/* <GroupMembers /> */}

        {/* <HeadCarousel></HeadCarousel> */}

        <div className={`page-content`}>
        <div className="row" style={{ background: "#fff", rowGap: "8rem" }}>
          {
            companies.map((item, index) => {
              const anchorId = item.name.replace(/\s+/g, '-').toLowerCase();
              if (index % 2 === 1 || window.screen.width < '600') {
                return <>
                  <div id={anchorId} className="col-lg-6 col-md-6 col-sm-6" style={{ display: "flex", justifyContent: "space-around", flexDirection: "column" }}>
                    <div style={{
                      fontFamily: "'Open Sans', sans-serif",
                      fontSize: "1.125rem",
                      color: "rgb(41, 55, 102)"
                    }}>{item.content}</div>
                    <a href={item.link} target="_blank" rel="noopener noreferrer" style={{textDecoration:"none"}}>
                      <div style={{ backgroundColor: 'var(--color-footer-bg)', width: '100px', color: "#fff", display: "flex", marginTop: "40px", justifyContent: "center", alignItems: "center", borderRadius: "4px", padding: "6px", cursor: "pointer" }}>
                        {t('learnMore')}
                      </div>
                    </a>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-6" style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
                    <img style={{ width: "70%", height: "100%" }} src={item.img}></img>
                  </div>
                </>
              } else {
                return <>
                  <div id={anchorId} className="col-lg-6 col-md-6 col-sm-6" style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
                    <img style={{ width: "70%", height: "100%" }} src={item.img}></img>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-6" style={{ display: "flex", justifyContent: "space-around", flexDirection: "column" }} >
                    <div style={{
                      fontFamily: "'Open Sans', sans-serif",
                      fontSize: "1.125rem",
                      color: "rgb(41, 55, 102)"
                    }}>{item.content}</div>
                    <a href={item.link} target="_blank" rel="noopener noreferrer"  style={{textDecoration:"none"}}>
                      <div style={{ backgroundColor: 'var(--color-footer-bg)', width: '100px', color: "#fff", display: "flex", marginTop: "40px", justifyContent: "center", alignItems: "center", borderRadius: "4px", padding: "6px", cursor: "pointer" }}>
                        {t('learnMore')}
                      </div>
                    </a>
                  </div>
                </>
              }
            })
          }
        </div>
        </div>

   

        <div className="h-20"></div>
        {/* <div className="page-content">
        <h2 className="text-color-primary font-lab-antiqua text-3xl md:text-4xl lg:text-5xl mb-7">
            Our Team
          </h2>

        </div> */}

        {/* 关于 ourteam */}
        {/* <Separator />
                    <div className="rwt-team-area rn-section-gap">
                        <div className="container">
                     
                            <TeamThree />
                        </div>
                    </div>
                    <Separator />  */}

        <ContactUs />
      </Layout>
    </>
  );
};

export default GroupMembersPage;
